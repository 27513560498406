import Vue from 'vue';
import '@/registerServiceWorker';

// Third Party Plugins
import '@/plugins/meta';
import '@/plugins/moment';
import i18n from '@/plugins/i18n';
import vuetify from '@/plugins/vuetify';
import 'vue2-animate/dist/vue2-animate.min.css';

// Own Plugins
import '@/plugins/dialog';
import '@/plugins/notify';
import '@/plugins/debounce';

// App
import app from '@/app/app.vue';
import store from '@/app/core/store';
import router from '@/app/core/router';

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(app),
}).$mount('#app');
