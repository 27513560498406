import '@mdi/font/css/materialdesignicons.css';

import Vue from 'vue';
import Vuetify, {
  VApp,
  VRow,
  VCol,
  VTabs,
  VMenu,
  VCard,
  VSelect,
  VTooltip,
  VToolbar,
  VDivider,
  VSubheader,
  VDataTable,
  VDataFooter,
  VSlideGroup,
  VSimpleTable,
  VSimpleCheckbox,
  VDataTableHeader,
} from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#fecd0c',
        secondary: '#1b283b',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
      },
    },
  },
  components: {
    VApp,
    VRow,
    VCol,
    VTabs,
    VMenu,
    VCard,
    VSelect,
    VTooltip,
    VToolbar,
    VDivider,
    VSubheader,
    VDataTable,
    VDataFooter,
    VSlideGroup,
    VSimpleTable,
    VSimpleCheckbox,
    VDataTableHeader,
  },
});
