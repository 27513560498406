










import { Component, Vue } from 'vue-property-decorator';
import { Snack } from '@/app/core/types';
import { PUSH_SNACK } from '@/app/core/events';
import EventBus from '@/app/core/services/event-bus.service';

// The time interval to wait beetwen hiding a snack an showing the next one.
const BETWEEN_SNACKS_INTERVAL = 500;

@Component
export default class SnackbarHost extends Vue {
  private snackQueue: Snack[] = [];

  pushSnack(snack: Snack) {
    this.snackQueue.push(snack);
  }

  processQueue() {
    this.snackQueue.shift();
  }

  listenToEvents() {
    EventBus.on(PUSH_SNACK, this.pushSnack);
  }

  onClose() {
    setTimeout(
      this.processQueue,
      BETWEEN_SNACKS_INTERVAL,
    );
  }

  created() {
    this.listenToEvents();
  }
}
