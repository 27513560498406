import Vue from 'vue';
import Vuex from 'vuex';

// Feature modules
import auth from '@/app/auth/store';
import user from '@/app/user/store';
import reservations from '@/app/reservations/store';

// Main module
import app from './app.store';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    app,
    auth,
    user,
    reservations,
  },
});
