import { Module, Mutation, VuexModule } from 'vuex-module-decorators';

@Module
export default class AppModule extends VuexModule {
  title = 'Hosttate';
  showDrawer: boolean | null = null;

  @Mutation
  setTitle(title: string) {
    this.title = title;
  }

  @Mutation
  toggleDrawer() {
    this.showDrawer = !this.showDrawer;
  }

  @Mutation
  setDrawer(value: boolean) {
    this.showDrawer = value;
  }
}
