import VueI18n from 'vue-i18n';

export type FormField = string | number | Array<string|number> | null | undefined;

export interface VForm extends HTMLFormElement {
  validate(): boolean;
  resetValidation(): void;
}

export type ValidationRule = true | string | VueI18n.LocaleMessages | (
  (value: FormField) => true | string | VueI18n.LocaleMessages
);

export interface ValidationRules {
  [key: string]: ValidationRule [] | ValidationRules;
}

export enum FormAction {
  Create = 'create',
  Update = 'update',
}
