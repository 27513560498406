export enum Role {
  Admin = 'Admin',
  HostAdmin = 'HostAdmin',
  HostAgent = 'HostAgent',
  HostOperative = 'HostOperative',
  Accountant = 'Accountant',
  Owner = 'Owner',
}

export interface UserProfileDto {
  firstname: string;
  lastname: string;
  picture?: string;
}

export default interface UserDto {
  id: string;
  email: string;
  role: Role;
  profile: UserProfileDto;
  active?: boolean;
}
