













import { Component, Vue } from 'vue-property-decorator';
import { Dialog } from '@/app/core/types';
import EventBus from '@/app/core/services/event-bus.service';
import { DIALOG_CLOSED, OPEN_DIALOG } from '@/app/core/events';

@Component
export default class DialogHost extends Vue {
  private dialogs: Dialog[] = [];

  onClose(dialog: Dialog, payload: unknown) {
    this.closeDialog(dialog);

    EventBus.emit(`${DIALOG_CLOSED}-${dialog.id}`, payload);
  }

  openDialog(dialog: Dialog) {
    this.dialogs.push(dialog);
  }

  closeDialog(dialog: Dialog) {
    const index = this.dialogs.indexOf(dialog);
    this.dialogs.splice(index, 1);
  }

  listenToEvents() {
    EventBus.on(OPEN_DIALOG, this.openDialog);
  }

  created() {
    this.listenToEvents();
  }
}
