import { RouteConfig } from 'vue-router';
import { Role } from '@/app/user/dto/user.dto';
import AppLayout from '@/app/core/layouts/app.layout.vue';

const ChannelManager = () => import(/* webpackChunkName: "channel-manager" */'../views/channel-manager.vue');
const HostAgents = () => import(/* webpackChunkName: "host-agents" */'../views/host-agents.vue');
const Owners = () => import(/* webpackChunkName: "owners" */'../views/owners.vue');

const routes: RouteConfig[] = [
  {
    path: '',
    component: AppLayout,
    children: [
      {
        name: 'channel-manager',
        path: '/channel-manager',
        component: ChannelManager,
        meta: {
          title: 'title.channelManager',
          requiresAuth: true,
          roles: [Role.HostAdmin],
        },
      },
      {
        name: 'host-agents',
        path: '/host-agents',
        component: HostAgents,
        meta: {
          title: 'title.hostAgents',
          requiresAuth: true,
          roles: [Role.HostAdmin],
        },
      },
      {
        name: 'owners',
        path: '/owners',
        component: Owners,
        meta: {
          title: 'title.owners',
          requiresAuth: true,
          roles: [Role.HostAdmin],
        },
      },
    ],
  },
];

export default routes;
