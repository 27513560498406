import {
  config,
  Module,
  Mutation,
  VuexModule,
} from 'vuex-module-decorators';
import ListReservationsDto from '../dto/list-reservations.dto';

// Get original errors
config.rawError = true;

@Module
export default class ReservationsModule extends VuexModule {
  filters: ListReservationsDto = {
    q: '',
    page: 1,
    itemsPerPage: 10,
  };

  @Mutation
  setFilters(filters: ListReservationsDto) {
    this.filters = filters;
  }
}
