import { RouteConfig } from 'vue-router';
import CoolImageLayout from '@/app/core/layouts/cool-image.layout.vue';

const CompleteRegistration = () => import(/* webpackChunkName: "complete-registration" */'../views/complete-registration.vue');
const RequestPasswordReset = () => import(/* webpackChunkName: "request-password-reset" */'../views/request-password-reset.vue');
const ResetPassword = () => import(/* webpackChunkName: "reset-password" */'../views/reset-password.vue');

const routes: RouteConfig[] = [
  {
    path: '',
    component: CoolImageLayout,
    children: [
      {
        name: 'complete-registration',
        path: '/complete-registration/:accessToken',
        component: CompleteRegistration,
      },
      {
        name: 'request-password-reset',
        path: '/request-password-reset',
        component: RequestPasswordReset,
      },
      {
        name: 'reset-password',
        path: '/reset-password/:accessToken',
        component: ResetPassword,
      },
    ],
  },
];

export default routes;
