import isNil from 'lodash/isNil';
import {
  config,
  Action,
  Module,
  Mutation,
  VuexModule,
} from 'vuex-module-decorators';
import { Role } from '@/app/user/dto/user.dto';
import storage from '@/app/core/services/storage.service';
import SignInDto from '../dto/sign-in.dto';
import auth from '../services/auth.service';

interface Auth {
  accessToken: string;
  role: Role;
}

// Get original errors
config.rawError = true;

// Initial state
const initialRole = storage.getItem<Role>('role');
const initialAccessToken = storage.getItem<string>('accessToken');

@Module
export default class AuthModule extends VuexModule {
  role = initialRole;
  accessToken = initialAccessToken;

  get isAuthenticated() {
    return !isNil(this.accessToken);
  }

  get isAdmin() {
    return this.role === Role.Admin;
  }

  get isHostAdmin() {
    return this.role === Role.HostAdmin;
  }

  get isHostAgent() {
    return this.role === Role.HostAgent;
  }

  get isOwner() {
    return this.role === Role.Owner;
  }

  get isHost() {
    return this.isHostAdmin || this.isHostAgent;
  }

  @Mutation
  setAuth({ accessToken, role }: Auth) {
    this.role = role;
    this.accessToken = accessToken;

    storage.setItem('role', role);
    storage.setItem('accessToken', accessToken);
  }

  @Mutation
  clearAuth() {
    this.role = undefined;
    this.accessToken = undefined;

    storage.removeItem('role');
    storage.removeItem('accessToken');
  }

  @Action
  async signIn(dto: SignInDto) {
    const response = await auth.signIn(dto);
    const { accessToken, user } = response.data;
    const { role, ...rolelessUser } = user;

    this.context.commit('setUser', rolelessUser);
    this.context.commit('setAuth', { role, accessToken });
  }

  @Action
  signOut() {
    this.context.commit('clearAuth');
    this.context.commit('clearUser');
  }
}
