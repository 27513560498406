import { RouteConfig } from 'vue-router';
import AppLayout from '@/app/core/layouts/app.layout.vue';
import { Role } from '@/app/user/dto/user.dto';

const Apartments = () => import(/* webpackChunkName: "apartments" */'../views/apartments.vue');
const NewApartment = () => import(/* webpackChunkName: "new-apartment" */'../views/new-apartment.vue');
const UpdateApartment = () => import(/* webpackChunkName: "update-apartment" */'../views/update-apartment.vue');
const ViewApartment = () => import(/* webpackChunkName: "view-apartment" */'../views/view-apartment.vue');

const routes: RouteConfig[] = [
  {
    path: '',
    component: AppLayout,
    children: [
      {
        name: 'apartments',
        path: '/apartments',
        component: Apartments,
        meta: {
          title: 'title.apartments',
          requiresAuth: true,
          roles: [Role.HostAdmin, Role.HostAgent, Role.Owner],
        },
      },
      {
        name: 'new-apartment',
        path: '/apartments/new',
        component: NewApartment,
        meta: {
          title: 'title.newApartment',
          requiresAuth: true,
          roles: [Role.HostAdmin],
        },
      },
      {
        name: 'update-apartment',
        path: '/apartments/update/:id',
        component: UpdateApartment,
        meta: {
          title: 'title.updateApartment',
          requiresAuth: true,
          roles: [Role.HostAdmin, Role.HostAgent],
        },
      },
      {
        name: 'view-apartment',
        path: '/apartments/details/:id/:tab?',
        component: ViewApartment,
        meta: {
          title: 'title.apartmentDetails',
          requiresAuth: true,
          roles: [Role.HostAdmin, Role.HostAgent, Role.Owner],
        },
      },
    ],
  },
];

export default routes;
