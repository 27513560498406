import _Vue from 'vue';
import notifyService from '../services/notify.service';

declare module 'vue/types/vue' {
  interface Vue {
    $notify: typeof notifyService;
  }
}

export default function NotifyPlugin(Vue: typeof _Vue) {
  // eslint-disable-next-line no-param-reassign
  Vue.prototype.$notify = notifyService;
}
