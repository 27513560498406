import { RouteConfig } from 'vue-router';
import CoolImageLayout from '@/app/core/layouts/cool-image.layout.vue';

const SignIn = () => import(/* webpackChunkName: "sign-in" */'../views/sign-in.vue');

const routes: RouteConfig[] = [
  {
    path: '',
    component: CoolImageLayout,
    children: [
      {
        name: 'sign-in',
        path: '/sign-in',
        component: SignIn,
      },
    ],
  },
];

export default routes;
