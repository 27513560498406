const NODE_ENV = process.env.NODE_ENV as string;
const API_BASE_URL = process.env.VUE_APP_API_BASE_URL as string;
const I18N_LOCALE = process.env.VUE_APP_I18N_LOCALE as string;
const I18N_FALLBACK_LOCALE = process.env.VUE_APP_I18N_FALLBACK_LOCALE as string;

export default Object.freeze({
  NODE_ENV,
  API_BASE_URL,
  I18N_LOCALE,
  I18N_FALLBACK_LOCALE,
});
