import {
  config,
  Action,
  Module,
  Mutation,
  VuexModule,
} from 'vuex-module-decorators';
import storage from '@/app/core/services/storage.service';
import userProfileService from '../services/user-profile.service';
import EditProfileDto from '../dto/edit-profile.dto';
import UserDto, { UserProfileDto } from '../dto/user.dto';

// Get original errors
config.rawError = true;

// Initial state
const initialUser = storage.getItem<UserDto>('user');

@Module
export default class UserModule extends VuexModule {
  id = initialUser?.id;
  email = initialUser?.email;
  profile = initialUser?.profile;

  @Mutation
  setUser({ id, email, profile }: UserDto) {
    this.id = id;
    this.email = email;
    this.profile = profile;

    storage.setItem('user', { id, email, profile });
  }

  @Mutation
  clearUser() {
    this.id = undefined;
    this.email = undefined;
    this.profile = undefined;

    storage.removeItem('user');
  }

  @Mutation
  async updateProfile(profile: UserProfileDto) {
    this.profile = profile

    storage.setItem('user', {
      profile,
      id: this.id,
      email: this.email,
    });
  }

  @Action({ commit: 'updateProfile' })
  async editProfile(dto: EditProfileDto) {
    return (await userProfileService.editProfile(dto)).data;
  }

  @Action({ commit: 'updateProfile' })
  async editProfilePicture(picture: File) {
    return (await userProfileService.editProfilePicture(picture)).data;
  }
}
