




































import {
  Vue,
  Prop,
  Emit,
  Component,
} from 'vue-property-decorator';
import { NotificationType } from '@/app/core/types';

@Component
export default class SnackbarNotification extends Vue {
  private value = false;

  private icons: { [type: string]: string } = {};

  private colors: { [type: string]: string } = {};

  @Prop({ type: Boolean, default: false }) readonly righty!: boolean;

  @Prop({ type: Boolean, default: false }) readonly lefty!: boolean;

  @Prop({ type: String, default: null }) readonly title!: string;

  @Prop({ type: String, required: true }) readonly message!: string;

  @Prop({
    type: String,
    default: NotificationType.Default,
    validator: (type: NotificationType) => Object.values(NotificationType).includes(type),
  }) readonly type!: NotificationType;

  @Emit('close')
  close() {
    this.value = false;
  }

  get color() {
    return this.type;
  }

  get icon() {
    switch (this.type) {
      case NotificationType.Success:
        return 'mdi-check-circle';
      case NotificationType.Info:
        return 'mdi-information';
      case NotificationType.Warning:
        return 'mdi-alert-circle';
      case NotificationType.Error:
        return 'mdi-alert';
      default:
        return 'mdi-bell';
    }
  }

  mounted() {
    this.value = true;
  }
}
