import axios from 'axios';
import env from '@/env';
import storage from './storage.service';

const axiosInstance = axios.create({
  baseURL: env.API_BASE_URL,
  headers: { 'Content-type': 'application/json' },
});

axiosInstance.interceptors.request.use((config) => {
  const accessToken = storage.getItem<string>('accessToken');

  if (accessToken) {
    return {
      ...config,
      headers: {
        ...config.headers,
        Authorization: `Bearer ${accessToken}`,
      },
    };
  }

  return config;
}, (error) => {
  // Do something with request error
  Promise.reject(error);
});

export default axiosInstance;
