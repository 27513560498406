import { Component } from 'vue';
import { TranslateResult } from 'vue-i18n';

export interface Snack {
  id: number;
  component: Component;
  payload?: object;
}

export interface Notification {
  title?: string | TranslateResult;
  message: TranslateResult;
  type?: NotificationType;
  options?: object;
}

export enum NotificationType {
  Info = 'info',
  Error = 'error',
  Accent = 'accent',
  Success = 'success',
  Warning = 'warning',
  Primary = 'primary',
  Default = 'default',
  Secondary = 'secondary',
}
