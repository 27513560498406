import api from '@/app/core/services/api.service';
import SignInDto from '../dto/sign-in.dto';
import SignInResponseDto from '../dto/sign-in-response.dto';

const signIn = (dto: SignInDto) => (
  api.post<SignInResponseDto>('/auth/sign-in', dto)
);

export default {
  signIn,
};
