import { AxiosError } from 'axios';
import { Action } from 'vuex-class';
import { Vue, Component } from 'vue-property-decorator';
import AuthModule from '@/app/auth/store';

@Component
export default class HandlesErrorMixin extends Vue {
  @Action
  private signOut!: typeof AuthModule.prototype.signOut;

  axios(error: Error) {
    return error as AxiosError;
  }

  isAxiosError(error: Error) {
    return this.axios(error).isAxiosError;
  }

  isForbiddenError(error: Error) {
    return this.isAxiosError(error) && this.isAxiosForbiddenError(this.axios(error));
  }

  isAxiosForbiddenError(error: AxiosError) {
    return error.response?.status === 401;
  }

  isBadRequestError(error: Error) {
    return this.isAxiosError(error) && this.isAxiosBadRequestError(this.axios(error));
  }

  isAxiosBadRequestError(error: AxiosError) {
    return error.response?.status === 400;
  }

  isNotFoundError(error: Error) {
    return this.isAxiosError(error) && this.isAxiosNotFoundError(this.axios(error));
  }

  isAxiosNotFoundError(error: AxiosError) {
    return error.response?.status === 404;
  }

  handleError(e: unknown) {
    const error = e as Error;

    if (this.isAxiosError(error)) {
      this.handleAxiosError(this.axios(error));
    } else {
      console.error(error);
    }
  }

  handleAxiosError(error: AxiosError) {
    if (this.isAxiosForbiddenError(error)) {
      this.handleForbiddenError();
    } else if (this.isAxiosBadRequestError(error)) {
      this.handleBadRequestError(error);
    } else {
      this.handleUnexpectedError();
    }
  }

  handleForbiddenError() {
    this.signOut();
    this.$router.push('/sign-in');

    this.$notify.error({
      title: this.$t('error.sessionExpired.title').toString(),
      message: this.$t('error.sessionExpired.message').toString(),
      options: { lefty: true },
    });
  }

  handleBadRequestError(error: AxiosError) {
    const message = error.response?.data.message || 'Bad request error';

    this.$notify.error({ message });
  }

  handleUnexpectedError() {
    this.$notify.error({
      title: this.$t('error.unexpectedError.title').toString(),
      message: this.$t('error.unexpectedError.message').toString(),
    });
  }
}
