// Domain suffix for storage keys to avoid possible conflict with other apps.
const DOMAIN = 'host.hosttate.com';

const getItem = <T = unknown>(key: string, defaultValue?: T) => {
  const json = sessionStorage.getItem(`${key}.${DOMAIN}`);

  return json ? JSON.parse(json) as T : defaultValue;
};

const setItem = <T = unknown>(key: string, obj: T) => {
  sessionStorage.setItem(`${key}.${DOMAIN}`, JSON.stringify(obj));
};

const removeItem = (key: string) => {
  sessionStorage.removeItem(`${key}.${DOMAIN}`);
};

export default {
  getItem,
  setItem,
  removeItem,
};
