











































































import { State, Action } from 'vuex-class';
import { Component, Vue } from 'vue-property-decorator';
import UserDto from '@/app/user/dto/user.dto';
import AuthModule from '@/app/auth/store';
import Avatar from '../avatar.vue';

@Component({
  components: {
    Avatar,
  },
})
export default class AppNavigationDrawerHeader extends Vue {
  @State((state) => state.user)
  private user!: UserDto;

  @Action
  private signOut!: typeof AuthModule.prototype.signOut;

  private showUserMenu = false;

  get picture() {
    return this.user?.profile?.picture
  }

  get email() {
    return this.user?.email || 'Loading user email..'
  }

  get fullname() {
    if (!this.user?.profile) return 'Loading user name...';

    return `${this.user.profile.firstname} ${this.user.profile.lastname}`;
  }

  onSignOut() {
    this.signOut();
    this.$router.push('/sign-in');
  }
}
