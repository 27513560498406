import Vue from 'vue';

/**
 * The Vue instance used as the event bus.
 */
const eventBus = new Vue();

/**
 * Emit an event.
 *
 * @param event The event name.
 * @param args The data related to the event.
 */
const emit = (event: string, ...args: unknown[]) => eventBus.$emit(event, ...args);

/**
 * Listen to an event and call a function when the event is emitted.
 *
 * @param event The event name
 * @param callback The function to execute when the event is emitted.
 */
const on = (event: string, callback: Function) => eventBus.$on(event, callback);

export default {
  emit,
  on,
};
