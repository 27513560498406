import { RouteConfig } from 'vue-router';
import AppLayout from '@/app/core/layouts/app.layout.vue';
import { Role } from '@/app/user/dto/user.dto';

const Reservations = () => import(/* webpackChunkName: "reservations" */'../views/reservations.vue');
const MyReservations = () => import(/* webpackChunkName: "my-reservations" */'../views/my-reservations.vue');
const NewReservation = () => import(/* webpackChunkName: "new-reservation" */'../views/new-reservation.vue');
const ViewReservation = () => import(/* webpackChunkName: "view-reservation" */'../views/view-reservation.vue');
const ReservationFromBlocking = () => import(/* webpackChunkName: "reservation-from-blocking" */'../views/reservation-from-blocking.vue');

const routes: RouteConfig[] = [
  {
    path: '',
    component: AppLayout,
    children: [
      {
        name: 'reservations',
        path: '/reservations',
        component: Reservations,
        meta: {
          title: 'title.reservations',
          requiresAuth: true,
          roles: [Role.HostAdmin, Role.HostAgent, Role.Owner],
        },
      },
      {
        name: 'my-reservations',
        path: '/my-reservations',
        component: MyReservations,
        meta: {
          title: 'title.myReservations',
          requiresAuth: true,
          roles: [Role.Owner],
        },
      },
      {
        name: 'new-reservation',
        path: '/reservations/new',
        component: NewReservation,
        meta: {
          title: 'title.newReservation',
          requiresAuth: true,
          roles: [Role.HostAdmin, Role.HostAgent, Role.Owner],
        },
      },
      {
        name: 'from-blocking',
        path: '/reservations/from-blocking/:id',
        component: ReservationFromBlocking,
        meta: {
          title: 'title.reservationFromBlocking',
          requiresAuth: true,
          roles: [Role.HostAdmin, Role.HostAgent],
        },
      },
      {
        name: 'view-reservation',
        path: '/reservations/details/:id/:tab?',
        component: ViewReservation,
        meta: {
          title: 'title.reservationDetails',
          requiresAuth: true,
          roles: [Role.HostAdmin, Role.HostAgent, Role.Owner],
        },
      },
    ],
  },
];

export default routes;
