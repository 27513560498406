import { RouteConfig } from 'vue-router';
import AppLayout from '@/app/core/layouts/app.layout.vue';

const MyProfile = () => import(/* webpackChunkName: "my-profile" */'../views/my-profile.vue');

const routes: RouteConfig[] = [
  {
    path: '',
    component: AppLayout,
    children: [
      {
        name: 'my-profile',
        path: '/my-profile',
        component: MyProfile,
        meta: {
          title: 'title.myProfile',
          requiresAuth: true,
        },
      },
    ],
  },
];

export default routes;
