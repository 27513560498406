import { Component } from 'vue';
import { Dialog } from '../types';
import EventBus from './event-bus.service';
import { OPEN_DIALOG, DIALOG_CLOSED } from '../events';

/**
 * Store the state of the id generator.
 */
let idCount = 0;

/**
 * Get a unique id to identify a dialog.
 */
const getId = () => {
  const id = idCount;
  idCount += 1;
  return id;
};

/**
 * Create a promise for the dialog opener to consume.
 * This allows to get feedback from the dialog.
 *
 * @param id The id of the dialog
 */
const createPromise = <T>(id: number) => new Promise<T>(
  (resolve, reject) => {
    EventBus.on(
      `${DIALOG_CLOSED}-${id}`,
      (payload: T) => (payload instanceof Error ? reject(payload) : resolve(payload)),
    );
  },
);

/**
 * Open a dialog component with a set of props (payload).
 * The generic type <T> is for the data returned by the dialog once is closed.
 *
 * @param component The dialog component to open.
 * Must emit the close event so the DialogHost notice when it is closed.
 * @param payload The data that must be passed to the dialog through props.
 */
const open = <T>(component: Component, payload?: object) => {
  const id = getId();
  const promise = createPromise<T>(id);
  const dialog: Dialog = { id, component, payload };

  EventBus.emit(OPEN_DIALOG, dialog);

  return promise;
};

export default {
  open,
};
