import { Component } from 'vue';
import { Snack } from '../types';
import { PUSH_SNACK } from '../events';
import eventBus from './event-bus.service';

/**
 * Store the state of the id generator.
 */
let idCount = 0;

/**
 * Get a unique id to identify a snack.
 */
const getId = () => {
  const id = idCount;
  idCount += 1;
  return id;
};

/**
 * Push a snackbar component with a set of props (payload) to the snackbar queue.
 *
 * @param component The snackbar component to push.
 * Must emit the close event so the SnackbarHost notice when it is closed.
 *
 * @param payload The data that must be passed to the snackbar component through props.
 */
const push = (component: Component, payload?: object) => {
  const id = getId();
  const snack: Snack = { id, component, payload };

  eventBus.emit(PUSH_SNACK, snack);
};

export default {
  push,
};
