












import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import userProfileService from '@/app/user/services/user-profile.service';
import HandlesErrorMixin from '../mixins/handles-error.mixin';

@Component
export default class Avatar extends Mixins(HandlesErrorMixin) {
  @Prop({ type: String, default: null })
  userId!: string;

  @Prop({ type: String, default: null })
  picture!: string;

  @Prop({ type: String, default: null })
  iconColor!: string;

  private loading = false;

  private src: string | ArrayBuffer | null = null;

  @Watch('userId')
  @Watch('picture', { immediate: true })
  async loadPicture() {
    if (!this.userId || !this.picture) return;

    this.loading = true;

    try {
      const picture = await this.downloadPicture();
      this.displayPicture(picture);
    } catch (error) {
      this.handleError(error);
    } finally {
      this.loading = false;
    }
  }

  async downloadPicture() {
    return (await userProfileService.getProfilePicture(this.userId)).data;
  }

  displayPicture(picture: Blob) {
    const reader = new FileReader();

    reader.readAsDataURL(picture);
    reader.onloadend = () => {
      this.src = reader.result;
    };
  }
}
