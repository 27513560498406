import Vue from 'vue';
import moment from 'moment';
import VueMoment from 'vue-moment';
import env from '@/env';
import storageService from '@/app/core/services/storage.service';

const storedLocale = storageService.getItem<string>('locale');
const locale = storedLocale || env.I18N_LOCALE || 'en';

moment.locale(locale)

Vue.use(VueMoment, { moment });
