import { RouteConfig } from 'vue-router';
import AppLayout from '@/app/core/layouts/app.layout.vue';
import { Role } from '@/app/user/dto/user.dto';

const Users = () => import(/* webpackChunkName: "users" */'../views/users.vue');

const routes: RouteConfig[] = [
  {
    path: '',
    component: AppLayout,
    children: [
      {
        name: 'users',
        path: '/users',
        component: Users,
        meta: {
          title: 'title.users',
          requiresAuth: true,
          roles: [Role.Admin],
        },
      },
    ],
  },
];

export default routes;
