import Vue from 'vue';
import VueI18n from 'vue-i18n';
import env from '@/env';
import en from '@/locales/en';
import es from '@/locales/es';
import StorageService from '@/app/core/services/storage.service';

Vue.use(VueI18n);

const defaultLocale = 'en';
const storedLocale = StorageService.getItem<string>('locale');

const i18n = new VueI18n({
  locale: storedLocale || env.I18N_LOCALE || defaultLocale,
  fallbackLocale: env.I18N_FALLBACK_LOCALE || defaultLocale,
  messages: { en, es },
});

export default i18n;
