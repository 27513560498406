









import { Component, Vue } from 'vue-property-decorator';
import DialogHost from './core/components/dialog-host.vue';
import SnackbarHost from './core/components/snackbar/snackbar-host.vue';

@Component({
  components: {
    DialogHost,
    SnackbarHost,
  },
})
export default class App extends Vue {}
