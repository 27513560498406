import _Vue from 'vue';
import dialogService from '../services/dialog.service';

declare module 'vue/types/vue' {
  interface Vue {
    $dialog: typeof dialogService;
  }
}

export default function DialogPlugin(Vue: typeof _Vue) {
  // eslint-disable-next-line no-param-reassign
  Vue.prototype.$dialog = dialogService;
}
