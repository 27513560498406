export default {
  btn: {
    signIn: 'Acceder',
  },
  error: {
    404: 'Error 404',
    pageNotFound: 'Página no encontrada',
  },
  hint: {
    dragAndDropOrClick: 'Arrastre los archivos o haga click para buscar',
  },
  label: {
    email: 'Correo electrónico',
    firstname: 'Nombre',
    lastname: 'Apellidos',
    password: 'Contraseña',
  },
  language: {
    en: 'English',
    es: 'Español',
  },
  notification: {
    registerCompleted: 'Registro completado, ya puede ingresar usando su usuario y su contraseña.',
  },
  title: {
    signIn: 'Acceder',
  },
  validation: {
    required: 'Este campo es requerido.',
    equalTo: 'Este campo debe ser igual al campo {to}.',
    minLength: 'Este campo debe contener al menos {threshold} caracteres.',
    maxLength: 'Este campo debe contener no más de {threshold} caracteres.',
    exactLength: 'Este campo debe contener exactamente {threshold} caracteres.',
    alpha: 'Este campo debe contener solamente letras.',
    alphaSpaces: 'Este campo debe contener solamente letras y espacios.',
    numeric: 'Este campo debe contener solamente números.',
    numericSpaces: 'Este campo debe contener solamente números y espacios.',
    alphaNumeric: 'Este campo debe contener solamente letras y números.',
    alphaNumericSpaces: 'Este campo debe contener solamente letras, números y espacios.',
    lt: 'Este campo debe ser menor que {threshold}.',
    lte: 'Este campo debe ser menor o igual que {threshold}.',
    gt: 'Este campo debe ser mayor que {threshold}.',
    gte: 'Este campo debe ser mayor o igual que {threshold}.',
    email: 'Este campo debe ser un correo electrónico válido',
    atLeast: {
      digits: 'Este campo debe contener al menos un digito. | Este campo debe contener al menos {n} digitos.',
      lowercase: 'Este campo debe contener al menos una letra minúscula. | Este campo debe contener al menos {n} letras minúsculas.',
      uppercase: 'Este campo debe contener al menos una letra mayúscula. | Este campo debe contener al menos {n} letras mayúsculas.',
      special: 'Este campo debe contener al menos un caracter especial (!@#$%^&*). | Este campo debe contener al menos {n} caracteres especiales (!@#$%^&*).',
    },
  },
};
