export default {
  btn: {
    signIn: 'Sign In',
    completeRegistration: 'Complete registration',
    sendRequest: 'Send request',
    resetPassword: 'Reset password',
    editProfile: 'Edit profile',
    changePassword: 'Change password',
    forgotPassword: 'Forgot password?',
    register: 'Register',
    add: 'Add',
    addRange: 'Add range',
    save: 'Save',
    saveChanges: 'Save changes',
    saving: 'Saving',
    cancel: 'Cancel',
    confirm: 'Confirm',
    create: 'Create',
    update: 'Update',
    delete: 'Delete',
    details: 'Details',
    sync: 'Sync',
    newApartment: 'New property',
    newReservation: 'Reservation',
    addOwner: 'Add owner',
    toReservation: 'To reservation',
    uploadImages: 'Add pictures',
  },
  tooltip: {
    activate: 'Activate',
    deactivate: 'Deactivate',
    copy: 'Copy to clipboard',
    edit: 'Edit',
    remove: 'Remove',
    editContract: 'Edit contract',
    removeOwner: 'Remove owner',
    details: 'Details',
    messages: 'Messages',
    sendMessage: 'Send message',
    removeRange: 'Remove range',
    listView: 'List view',
    gridView: 'Grid view',
    createReservation: 'Create reservation',
    createFromBlocking: 'Create from blocking',
    resendRegistrationEmail: 'Resend registration email',
    deleteUser: 'Delete user',
    deleteOwner: 'Delete owner',
    deleteAgent: 'Delete agent',
    addPictures: 'Add pictures',
    removePicture: 'Remove picture',
  },
  success: {
    profileEdited: 'Profile updated successfully',
    passwordChanged: 'Password changed successfully',
    userRegistered: 'User registered successfully',
    iCalURLCopied: 'iCal URL copied to the clipboard',
    webhookURLCopied: 'Webhook URL copied to the clipboard',
    smoobuConfigUpdated: 'Smoobu config updated successfully',
    smoobuSynchronized: 'Smoobu account synchronized successfully',
    apartmentCreated: 'Property created successfully',
    apartmentUpdated: 'Property updated successfully',
    apartmentDeleted: 'Property deleted successfully',
    reservationCreated: 'Reservation created successfully',
    reservationCancelled: 'Reservation cancelled successfully',
    reservationDeleted: 'Reservation deleted successfully',
    assignedApartmentsUpdated: 'Assigned properties updated successfully',
    apartmentOwnerRemoved: "Property's owner removed successfully",
    apartmentOwnerAdded: "Property's owner added successfully",
    contractUpdated: 'Contract updated successfully.',
    messageSent: 'Message sent successfully.',
    changesSaved: 'Changes saved successfully.',
    registrationEmailResent: 'Registration email resent successfully',
    userDeleted: 'User deleted successfully',
    ownerDeleted: 'Owner deleted successfully',
    agentDeleted: 'Agent deleted successfully',
    iCalSyncConfigUpdated: 'iCal sync config updated successfully',
  },
  error: {
    404: 'Error 404',
    pageNotFound: 'Page not found',
    403: 'Error 403',
    forbidden: 'Permission denied',
    wrongEmailOrPassword: 'Wrong email or password',
    invalidRegistrationLink: 'Invalid registration link',
    invalidResetPasswordLink: {
      title: 'Invalid reset password link',
      message: 'Did you use it within an hour?',
    },
    sessionExpired: {
      title: 'Session expired',
      message: 'Sign in again to continue',
    },
    incorrectPassword: {
      title: 'Incorrect password',
      message: 'Please check it and try again',
    },
    unexpectedError: {
      title: 'Unexpected error',
      message: 'Please, try again later',
    },
  },
  message: {
    confirmDeleteApartment: 'Property <strong>{name}</strong> is about to be deleted.',
    confirmDeleteUser: 'User <strong>{name}</strong> is about to be deleted.',
    confirmDeleteReservation: 'Reservation of property <strong>{apartment}</strong> is about to be deleted.',
    confirmCancelReservation: 'Reservation of property <strong>{apartment}</strong> is about to be cancelled.',
    confirmRemoveOwner: 'Owner <strong>{owner}</strong> is about to be removed from property <strong>{apartment}</strong>.',
    confirmRemoveRange: `
      Reservation range is about to be removed.
      After you save the changes
      <strong>
        all records of reservations made using this range will be lost.
      </string>
    `,
  },
  hint: {
    dragAndDropOrClick: 'Drag & Drop or click to browse',
    requestPasswordReset: `
      You must write the email address related to your account in order
      to send you a link that can be used to safely reset your password.
    `,
    resetPassword: 'Write your new password here.',
    smoobuApiKey: `
      Here you must copy the Api Key related to your Smoobu account.
      It can be found under the Developers Settings of your account.
    `,
    smoobuWebhookURL: `
      After you set up your Api Key, you must copy this URL and set it as
      your webhook URL in the Developer Settings of you account in order
      to receive notifications.
    `,
    smoobuChannelId: `
      Here you must copy the Channel ID related to your Smoobu account.
      It can be found under the Developers Settings of your account.
    `,
    smoobuBlockedChannelId: `
      Here you must copy the Blocked Channel ID related to your Smoobu account.
      It can be found under the Developers Settings of your account.
    `,
    iCalUrl: `
      Here you can copy the property iCal URL to be used in other platforms.
    `,
    iCalSyncUrl: `
      Here you can paste the iCal URL you want the property to sync from.
    `,
  },
  label: {
    iCalUrl: 'iCal URL',
    iCalSyncUrl: 'iCal Sync URL',
    iCalSyncActive: 'Auto-sync active?',
    name: 'Name',
    description: 'Description',
    email: 'Email',
    role: 'Role',
    active: 'Active',
    firstname: 'Firstname',
    lastname: 'Lastname',
    picture: 'Picture',
    pictures: 'Pictures',
    password: 'Password',
    cancelled: 'Cancelled',
    newPassword: 'New password',
    search: 'Search',
    incompleteRegistration: 'Incomplete registration',
    apiKey: 'Api Key',
    webhookUrl: 'Webhook URL',
    channelId: 'Channel ID',
    blockedChannelId: 'Blocked Channel ID',
    address: 'Address',
    city: 'City',
    country: 'Country',
    zip: 'Zip Code',
    latitude: 'Latitude',
    longitude: 'Longitude',
    amenities: 'Amenities',
    and: 'and',
    arrivalIn: 'Arrival in',
    departureIn: 'Departure in',
    smoobuId: 'SMB',
    upToGuests: 'No guests | Only one guest | Up to {count} guests',
    bedrooms: 'No bedrooms | One bedroom | {count} bedrooms',
    bathrooms: 'No bathrooms | One bathroom | {count} bathrooms',
    children: 'No children | One child | {count} children',
    adults: 'No adults | One adult | {count} adults',
    childrenInForm: 'Children',
    adultsInForm: 'Adults',
    formBedrooms: 'Bedrooms',
    formBathrooms: 'Bathrooms',
    maxOccupancy: 'Max Occupancy',
    noAddress: 'Address is missing',
    noZip: 'Zip Code is missing',
    noCity: 'City is missing',
    noCountry: 'Country is missing',
    noAmenities: 'No amenities on this property',
    noData: 'No data available',
    noOwners: 'No owners have been added yet.',
    noGuest: 'Guest information is missing',
    noChannel: 'Channel information is missing',
    noDescription: 'Description is missing',
    noPriceElements: 'No payment details are available.',
    loading: 'Loading',
    apartment: 'Property',
    arrival: 'Arrival',
    departure: 'Departure',
    checkIn: 'Check in',
    checkOut: 'Check out',
    speaks: 'Speaks',
    at: 'at',
    guest: 'Guest',
    channel: 'Channel',
    actions: 'Actions',
    to: 'to',
    To: 'To',
    location: 'Location',
    rooms: 'Rooms',
    totalPrice: 'Total price',
    subject: 'Write the subject',
    text: 'Write the message',
    language: 'Language',
    phone: 'Phone',
    notice: 'Notice',
    payment: 'Payment',
    price: 'Price',
    pricePaid: 'Price paid?',
    prepayment: 'Pre-payment',
    prepaymentPaid: 'Pre-payment paid?',
    deposit: 'Deposit',
    depositPaid: 'Deposit paid?',
    blocking: 'Blocking',
    ownershipPercent: 'Ownership percent',
    canReserve: 'Can reserve',
    cantReserve: "Can't reserve",
    limitedReservations: 'Limited reservations',
    unlimitedReservations: 'Unlimited reservations',
    reservationRanges: 'Reservation ranges',
    from: 'From',
    days: 'Days',
    noRanges: 'No ranges has been added.',
    ownership: 'Ownership',
    reservedByOwner: 'Reserved by owner',
    reservedByMe: 'Reserved by me',
    nights: 'Nights',
    platform: 'Platform',
    occupancy: 'Occupancy',
    revenue: 'Revenue',
    occupancyAndRevenue: 'Occupancy & Revenue',
    allProperties: 'All properties',
    people: 'Person | People',
    reservation: 'Reservation',
    cancellation: 'Cancellation',
    'modification of booking': 'Modification of booking',
    welcomeMessage: 'Welcome message',
    checkInReminder: 'Check-in reminder',
    checkOutReminder: 'Check-out reminder',
    noApartmentPictures: 'No pictures uploaded for this apartment.',
  },
  language: {
    en: 'English',
    es: 'Spanish',
  },
  notification: {
    registrationCompleted: `
      You have successfully completed your registration to the Hosttate's App
      for Hosts & Owners. You can now Sign In and start using the app.
    `,
    resetPasswordEmailSent: `
      If the email address you provided is correct, then we sent you an email
      containing a one hour valid link to safely reset your password.
    `,
    passwordReset: `
      Your password was reset successfully and you can now sign in using you new
      password. Please, don't forget it this time 😅.
    `,
  },
  tabs: {
    editProfile: 'Edit profile',
    changePassword: 'Change password',
    info: 'Info',
    payment: 'Payment',
    owners: 'Owners',
    reservations: 'Reservations',
    autoMessages: 'Auto Messages',
    messages: 'Messages',
    contract: 'Contract',
  },
  title: {
    signIn: 'Sign In',
    signOut: 'Sign Out',
    completeRegistration: 'Complete registration',
    registrationCompleted: 'Registration completed',
    requestPasswordReset: 'Request password reset',
    resetPasswordEmailSent: 'Email sent',
    resetPassword: 'Reset password',
    passwordReset: 'Password reset',
    myProfile: 'My Profile',
    dashboard: 'Dashboard',
    channelManager: 'Channel Manager',
    hostAgents: 'Agents',
    owners: 'Owners',
    apartments: 'Properties',
    reservations: 'Reservations',
    myReservations: 'My Reservations',
    newApartment: 'New Property',
    newReservation: 'New Reservation',
    reservationFromBlocking: 'Create Reservation from Blocking',
    updateApartment: 'Update Property',
    deleteApartment: 'Delete property',
    deleteReservation: 'Delete reservation',
    cancelReservation: 'Cancel reservation',
    apartmentDetails: 'Property Details',
    reservationDetails: 'Reservation Details',
    users: 'Users',
    registerUser: 'Register user',
    assignApartments: 'Assign properties',
    reservationMessages: 'Reservation messages',
    removeOwner: 'Remove owner',
    addOwner: 'Add owner',
    editOwnerContract: "Edit owner's contract",
    removeRange: 'Remove range',
    filters: 'Filters',
    upcomingReservations: 'Upcoming Arrivals / Departures',
    activityFeed: 'Activity Feed',
    deleteUser: 'Delete user',
  },
  pageTitle: {
    signIn: 'Hosttate - Sign In',
    completeRegistration: 'Hosttate - Complete registration',
    requestPasswordReset: 'Hosttate - Request password reset',
    resetPassword: 'Hosttate - Reset password',
    myProfile: 'Hosttate - My Profile',
    dashboard: 'Hosttate - Dashboard',
    channelManager: 'Hosttate - Channel Manager',
    hostAgents: 'Hosttate - Host Agents',
    owners: 'Hosttate - Owners',
    apartments: 'Hosttate - Properties',
    reservations: 'Hosttate - Reservations',
    myReservations: 'Hosttate - My Reservations',
    newApartment: 'Hosttate - New Property',
    newReservation: 'Hosttate - New Reservation',
    reservationFromBlocking: 'Hosttate - Create Reservation from Blocking',
    apartmentDetails: 'Hosttate - Property Details',
    reservationDetails: 'Hosttate - Reservation Details',
    updateApartment: 'Hosttate - Update Property',
    users: 'Hosttate - Users',
  },
  validation: {
    required: 'This field is required.',
    equalTo: 'This field must be equal to {to}.',
    minLength: 'This field must be at least {threshold} characters long.',
    maxLength: 'This field must be at most {threshold} characters long.',
    exactLength: 'This field must be {threshold} characters long.',
    alpha: 'This field must only contain letters.',
    alphaSpaces: 'This field must only contain letters and white spaces.',
    numeric: 'This field must only contain digits.',
    numericSpaces: 'This field must only contain digits and white spaces.',
    alphaNumeric: 'This field must only contain letters and digits.',
    alphaNumericSpaces: 'This field must only contain letters, digits and white spaces.',
    lt: 'This field must be lower than {threshold}.',
    lte: 'This field must be lower than or equals {threshold}.',
    gt: 'This field must be grater than {threshold}.',
    gte: 'This field must be grater than or equals {threshold}.',
    email: 'This field must be a valid email address.',
    atLeast: {
      digits: 'This field must contain at least a digit. | This field must contain at least {n} digits.',
      lowercase: 'This field must contain at least a lowercase letter. | This field must contain at least {n} lowercase letters.',
      uppercase: 'This field must contain at least an uppercase letter. | This field must contain at least {n} uppercase letters.',
      special: 'This field must contain at least a special character (!@#$%^&*.). | This field must contain at least {n} spacial characters (!@#$%^&*.).',
    },
    // Custom validation
    emailExists: 'This email is already taken.',
    apiKeyExists: 'This Api Key does not exists.',
  },
};
