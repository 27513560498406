import { RouteConfig } from 'vue-router';
import AppLayout from '@/app/core/layouts/app.layout.vue';

const Dashboard = () => import(/* webpackChunkName: "dashboard" */'../views/dashboard.vue');

const routes: RouteConfig[] = [
  {
    path: '',
    component: AppLayout,
    children: [
      {
        name: 'dashboard',
        path: '/dashboard',
        component: Dashboard,
        meta: {
          title: 'title.dashboard',
          requiresAuth: true,
        },
      },
    ],
  },
];

export default routes;
