






































import { State, Mutation } from 'vuex-class';
import { Component, Vue } from 'vue-property-decorator';
import AppModule from '../store/app.store';
import AppNavigationDrawer from '../components/navigation-drawer/app-navigation-drawer.vue';

@Component({
  components: {
    AppNavigationDrawer,
  },
})
export default class AppLayout extends Vue {
  @State(state => state.app.title)
  private title!: string;

  @Mutation
  private toggleDrawer!: typeof AppModule.prototype.toggleDrawer;
}
