






























import { State, Mutation, Getter } from 'vuex-class';
import { Component, Vue } from 'vue-property-decorator';
import AuthModule from '@/app/auth/store';
import AppModule from '../../store/app.store';
import AppNavigationDrawerHeader from './app-navigation-drawer-header.vue';

@Component({
  components: {
    AppNavigationDrawerHeader,
  },
})
export default class AppNavigationDrawer extends Vue {
  @State(state => state.app.showDrawer)
  private showDrawer!: boolean;

  @Getter
  private isAdmin!: typeof AuthModule.prototype.isAdmin;

  @Getter
  private isHostAdmin!: typeof AuthModule.prototype.isHostAdmin;

  @Getter
  private isHostAgent!: typeof AuthModule.prototype.isHostAgent;

  @Getter
  private isOwner!: typeof AuthModule.prototype.isOwner;

  @Mutation
  private setDrawer!: typeof AppModule.prototype.setDrawer;

  get links() {
    return [
      {
        icon: 'mdi-view-dashboard',
        title: 'title.dashboard',
        to: '/dashboard',
        condition: true,
      },
      {
        icon: 'mdi-cog-sync',
        title: 'title.channelManager',
        to: '/channel-manager',
        condition: this.isHostAdmin,
      },
      {
        icon: 'mdi-face-agent',
        title: 'title.hostAgents',
        to: '/host-agents',
        condition: this.isHostAdmin,
      },
      {
        icon: 'mdi-briefcase-account',
        title: 'title.owners',
        to: '/owners',
        condition: this.isHostAdmin,
      },
      {
        icon: 'mdi-home-group',
        title: 'title.apartments',
        to: '/apartments',
        condition: this.isHostAdmin || this.isHostAgent || this.isOwner,
      },
      {
        icon: 'mdi-calendar-multiple',
        title: 'title.reservations',
        to: '/reservations',
        condition: this.isHostAdmin || this.isHostAgent || this.isOwner,
      },
      {
        icon: 'mdi-calendar-account',
        title: 'title.myReservations',
        to: '/my-reservations',
        condition: this.isOwner,
      },
      {
        icon: 'mdi-account-multiple',
        title: 'title.users',
        to: '/users',
        condition: this.isAdmin,
      },
    ];
  }
}
