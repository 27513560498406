import api from '@/app/core/services/api.service';
import EditProfileDto from '@/app/user/dto/edit-profile.dto';
import ChangePasswordDto from '@/app/user/dto/change-password.dto';
import { UserProfileDto } from '../dto/user.dto';

const editProfile = (dto: EditProfileDto) => (
  api.put<UserProfileDto>('/user/profile', dto)
);

const getProfilePicture = (userId: string) => (
  api.get<Blob>(`/user/profile/picture/${userId}`, {
    responseType: 'blob',
  })
);

const editProfilePicture = (picture: File) => {
  const formData = new FormData();
  formData.append('picture', picture);

  return api.put<UserProfileDto>('/user/profile/picture', formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

const changePassword = (dto: ChangePasswordDto) => (
  api.put('/user/profile/change-password', dto)
);

export default {
  editProfile,
  changePassword,
  getProfilePicture,
  editProfilePicture,
};
