import Vue from 'vue';
import VueRouter, { NavigationGuardNext, Route, RouteConfig } from 'vue-router';

// Modules
import profileRoutes from '@/app/profile/routes';
import dashboardRoutes from '@/app/dashboard/routes';
import authRoutes from '@/app/auth/routes';
import userRoutes from '@/app/user/routes';
import hostRoutes from '@/app/host/routes';
import usersRoutes from '@/app/users/routes';
import apartmentsRoutes from '@/app/apartments/routes';
import reservationsRoutes from '@/app/reservations/routes';

// The store
import store from '../store';

// Fallback component
const Error403 = () => import(/* webpackChunkName: "error-403" */'../views/error-403.vue');
const Error404 = () => import(/* webpackChunkName: "error-404" */'../views/error-404.vue');

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  { path: '/', redirect: '/dashboard' },
  { name: 'forbidden', path: '/forbidden', component: Error403 },

  // Feature modules routes
  ...profileRoutes,
  ...dashboardRoutes,
  ...authRoutes,
  ...userRoutes,
  ...hostRoutes,
  ...usersRoutes,
  ...apartmentsRoutes,
  ...reservationsRoutes,

  // Add routes above ↑
  // This is the fallback route
  {
    path: '*',
    name: '404',
    component: Error404,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

const setRouteTitle = (to: Route) => {
  const titledRecord = to.matched.find((record) => record.meta.title);
  if (titledRecord) {
    store.commit('setTitle', titledRecord.meta.title);
  }
};

const checkAuth = (to: Route, next: NavigationGuardNext<Vue>) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters.isAuthenticated) {
      next({
        name: 'sign-in',
        query: { redirect: to.fullPath },
      });

      return false;
    }
  }

  return true;
};

const checkRoles = (to: Route, next: NavigationGuardNext<Vue>) => {
  const protectedRecord = to.matched.find((record) => record.meta.roles);

  if (protectedRecord?.meta?.roles) {
    const { roles } = protectedRecord.meta;

    if (!roles.includes(store.state.auth.role)) {
      next({ name: 'forbidden' });

      return false;
    }
  }

  return true;
};

router.beforeEach((to, from, next) => {
  setRouteTitle(to);

  if (!checkAuth(to, next)) return;

  checkRoles(to, next);

  next();
});

export default router;
