import isString from 'lodash/isString';
import SnackBarService from './snackbar.service';
import { Notification, NotificationType } from '../types';
import SnackbarNotification from '../components/snackbar/snackbar-notification.vue';

const processArgs = (notification: Notification | string) => (
  isString(notification) ? { message: notification } : { ...notification }
);

const buildNotify = (type: NotificationType) => (
  (notification: Notification | string) => {
    const { title, message, options } = processArgs(notification);

    SnackBarService.push(SnackbarNotification, {
      ...options, title, message, type,
    });
  }
);

/**
 * Push a deafult notification.
 *
 * @param param0 The notification data.
 */
const notifyDefault = buildNotify(NotificationType.Default);

/**
 * Push a success notification.
 *
 * @param param0 The notification data.
 */
const success = buildNotify(NotificationType.Success);

/**
 * Push an info notification.
 *
 * @param param0 The notification data.
 */
const info = buildNotify(NotificationType.Info);

/**
 * Push a warning notification.
 *
 * @param param0 The notification data.
 */
const warning = buildNotify(NotificationType.Warning);

/**
 * Push an error notification.
 *
 * @param param0 The notification data.
 */
const error = buildNotify(NotificationType.Error);

/**
 * Push a primary notification.
 *
 * @param param0 The notification data.
 */
const primary = buildNotify(NotificationType.Primary);

/**
 * Push a secondary notification.
 *
 * @param param0 The notification data.
 */
const secondary = buildNotify(NotificationType.Secondary);

/**
 * Push a accent notification.
 *
 * @param param0 The notification data.
 */
const accent = buildNotify(NotificationType.Accent);

export default {
  default: notifyDefault,
  success,
  info,
  warning,
  error,
  primary,
  secondary,
  accent,
};
